/* eslint-disable */
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const TableBodyRow = ({
  tableRowClassStyle,
  tableRowStyle,
  tableCellData,
  tableCellClassStyle,
  tableCellStyle,
  tableCellParticularColumnStyle,
  tableCellAlign,
  tableCellParticularAlign,
  isTableCellOnClick,
  onClickFunc,
}) => {
  return (
    <TableRow className={tableRowClassStyle} style={tableRowStyle}>
      {tableCellData &&
        tableCellData.length &&
        tableCellData.map((item, index) => (
          <TableCell
            className={tableCellClassStyle}
            align={
              tableCellParticularAlign.length
                ? tableCellParticularAlign.filter((item1) =>
                    item1.columnIndex.includes(index + 1)
                  ).length
                  ? tableCellParticularAlign.filter((item1) =>
                      item1.columnIndex.includes(index + 1)
                    )[0].align
                  : tableCellAlign
                : tableCellAlign
            }
            onClick={() => {
              if (isTableCellOnClick) {
                onClickFunc(item.id);
              }
            }}
            style={{
              ...(tableCellParticularColumnStyle.length
                ? tableCellParticularColumnStyle.filter((item1) =>
                    item1.columnIndex.includes(index + 1)
                  ).length
                  ? tableCellParticularColumnStyle.filter((item1) =>
                      item1.columnIndex.includes(index + 1)
                    )[0].style
                  : {}
                : {}),
              ...tableCellStyle,
            }}
            key={index}
          >
            {item}
          </TableCell>
        ))}
    </TableRow>
  );
};

TableBodyRow.propTypes = {
  tableRowStyle: PropTypes.object,
  tableRowClassStyle: PropTypes.string,
  tableCellData: PropTypes.array.isRequired,
  tableCellClassStyle: PropTypes.string,
  tableCellParticularColumnStyle: PropTypes.array,
  tableCellAlign: PropTypes.string,
  tableCellStyle: PropTypes.object,
  isTableRowOnClick: PropTypes.bool,
  onClickFunc: PropTypes.func,
  tableCellParticularAlign: PropTypes.array,
};

TableBodyRow.defaultProps = {
  tableRowStyle: {},
  tableRowClassStyle: "",
  tableCellClassStyle: "",
  tableCellParticularColumnStyle: [],
  tableCellAlign: "center",
  tableCellStyle: {},
  isTableRowOnClick: false,
  onClickFunc: null,
  tableCellParticularAlign: [],
};

export default TableBodyRow;
