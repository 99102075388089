/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const RoleContext = createContext(null);

const RoleProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    axios
      .get(`${urls.roleListing}`)
      .then((resp) => {
        const data = resp.data;
        setRoles(data);
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return <RoleContext.Provider value={roles}>{children}</RoleContext.Provider>;
};

const useRoles = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error("useRoles must be used within a RoleProvider");
  }
  return context;
};

RoleProvider.propTypes = {
  children: PropTypes.node,
};

RoleProvider.defaultProps = {
  children: "",
};

export { RoleProvider, useRoles };
