/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const CityContext = createContext(null);

const CityProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios
      .get(`${urls.cityListing}`)
      .then((resp) => {
        const data = resp.data;
        setCities(
          data?.length
            ? data?.filter(
                (each) => ![null, undefined].includes(each.city_name)
              )
            : []
        );
      })
      .catch((e) => {});
  }, [user.personal_info.token]);

  return <CityContext.Provider value={cities}>{children}</CityContext.Provider>;
};

const useCities = () => {
  const context = useContext(CityContext);
  if (!context) {
    throw new Error("useBranches must be used within a BranchProvider");
  }
  return context;
};

CityProvider.propTypes = {
  children: PropTypes.node,
};

CityProvider.defaultProps = {
  children: "",
};

export { CityProvider, useCities };
