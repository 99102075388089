/* eslint-disable */
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const RecordNotFound = ({ label, marginTop }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
        <Typography
          variant="h3"
          style={{ color: "blue", marginTop: marginTop }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

RecordNotFound.propTypes = {
  label: PropTypes.string,
  marginTop: PropTypes.string,
};

RecordNotFound.defaultProps = {
  label: "Record Not Found",
  marginTop: "100px",
};

export default RecordNotFound;
