/* eslint-disable */
import React, { createContext, useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../auth/useAuth";
import urls from "../../../urls";
import { useAxios } from "../useAxios";

const ContactSourceContext = createContext(null);

const ContactSourceProvider = ({ children }) => {
  const { user } = useAuth();
  const { axios } = useAxios();
  const [contactSource, setContactSource] = useState([]);

  useEffect(() => {}, [user.personal_info.token]);

  return (
    <ContactSourceContext.Provider value={contactSource}>
      {children}
    </ContactSourceContext.Provider>
  );
};

const useContactSource = () => {
  const context = useContext(ContactSourceContext);
  if (!context) {
    throw new Error("useBranches must be used within a BranchProvider");
  }
  return context;
};

ContactSourceProvider.propTypes = {
  children: PropTypes.node,
};

ContactSourceProvider.defaultProps = {
  children: "",
};

export { ContactSourceProvider, useContactSource };
