/* eslint-disable */
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const TableHeader = ({
  tableRowClassStyle,
  tableRowStyle,
  tableCellData,
  tableCellClassStyle,
  tableCellParticularColumnStyle,
  tableCellAlign,
  tableCellParticularAlign,
  tableHeadStyle,
  tableHeadClassStyle,
}) => {
  return (
    <TableHead
      style={{ backgroundColor: "white", ...tableHeadStyle }}
      className={tableHeadClassStyle}
    >
      <TableRow className={tableRowClassStyle} style={tableRowStyle}>
        {tableCellData &&
          tableCellData.length &&
          tableCellData.map((item, index) => (
            <TableCell
              className={tableCellClassStyle}
              align={
                tableCellParticularAlign.length
                  ? tableCellParticularAlign.filter((item1) =>
                      item1.columnIndex.includes(index + 1)
                    ).length
                    ? tableCellParticularAlign.filter((item1) =>
                        item1.columnIndex.includes(index + 1)
                      )[0].align
                    : tableCellAlign
                  : tableCellAlign
              }
              style={{
                ...(tableCellParticularColumnStyle.length
                  ? tableCellParticularColumnStyle.filter((item1) =>
                      item1.columnIndex.includes(index + 1)
                    ).length
                    ? tableCellParticularColumnStyle.filter((item1) =>
                        item1.columnIndex.includes(index + 1)
                      )[0].style
                    : {}
                  : {}),
                backgroundColor: "#F3F3F3",
              }}
              key={index}
            >
              {item}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  tableRowClassStyle: PropTypes.string,
  tableRowStyle: PropTypes.object,
  tableCellData: PropTypes.array.isRequired,
  tableCellClassStyle: PropTypes.string,
  tableCellParticularColumnStyle: PropTypes.array,
  tableCellAlign: PropTypes.string,
  tableHeadStyle: PropTypes.object,
  tableHeadClassStyle: PropTypes.string,
  tableCellParticularAlign: PropTypes.array,
};

TableHeader.defaultProps = {
  tableRowStyle: {},
  tableRowClassStyle: "",
  tableCellClassStyle: "",
  tableCellParticularColumnStyle: [],
  tableCellAlign: "center",
  tableHeadStyle: {},
  tableHeadClassStyle: "",
  tableCellParticularAlign: [],
};

export default TableHeader;
