/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./utility/theme";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider, AlertProvider } from "./utility/customHooks";
import { Helmet } from "react-helmet";
import getProjectText from "./utility/helper/getProjectText";

ReactDOM.render(
  <>
    <div className="root">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {getProjectText().includes("recruitment")
            ? "Letseduvate Recruitment - A talent acquisition management platform"
            : getProjectText().charAt(0).toUpperCase() +
              getProjectText().slice(1)}
        </title>
      </Helmet>
    </div>
    <MuiThemeProvider theme={theme}>
      <AlertProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </AlertProvider>
    </MuiThemeProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
