/* eslint-disable */
import { Button, Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const GetBackButton = ({ onClick, type, style, disabled, className }) => {
  return (
    <Grid item>
      <Button
        className={className}
        variant="contained"
        color="primary"
        size="small"
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {type}
      </Button>
    </Grid>
  );
};

GetBackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

GetBackButton.defaultProps = {
  style: {},
  disabled: false,
  className: null,
};

export default GetBackButton;
