/* eslint-disable */
let BASE;
let MEDIA_BASE;
let HOST;
let HOSTNAME;
let HOSTREPORTNAME;
let PORT;
let WEBSOCKETBASE;
let BASEREPORT;
const PROTO = "http";
const PROTO_HTTPS = "https";
const WS = "ws";
const WSS = "wss";
let BUCKETURL;

if (window.location.hostname !== "localhost") {
  HOST = window.location.hostname;
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOST}/qbox/reports`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  BUCKETURL = `${PROTO_HTTPS}://recruitment-bucket.s3.ap-south-1.amazonaws.com/`;
  MEDIA_BASE = BASE;
} else {
  // HOSTNAME = "localhost";
  // PORT = "8000";
  // BASE = `${PROTO}://${HOSTNAME}:${PORT}/qbox`;
  // BASEREPORT = `${PROTO}://${HOSTNAME}:${PORT}/qbox/reports`;
  // WEBSOCKETBASE = `${WS}://${HOSTNAME}:${PORT}`;
  // MEDIA_BASE = BASE;
  // BUCKETURL = `${PROTO_HTTPS}://recruitment-bucket.s3.ap-south-1.amazonaws.com/`;

  HOST = "recruitment.dev-k8.letseduvate.com";
  // HOST = "my-app.loca.lt";
  // HOST = "slimy-bottles-go-122-186-252-102.loca.lt";
  // HOST = "192.168.100.48:8001";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOST}/qbox/reports`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  BUCKETURL = `${PROTO_HTTPS}://recruitment-bucket.s3.ap-south-1.amazonaws.com/`;
  MEDIA_BASE = BASE;
}

const urls = {
  websocketUrl: `${WEBSOCKETBASE}/ws/notification`,
  S3BucketUrl: BUCKETURL,
  base: `${BASE}`,
  BASE,
  MEDIA_BASE,
  checkPasswordDate: `${BASE}/check_password_date/`,
  pipHistory: `${BASE}/employee_piptag_history/`,
  getUserName: `${BASE}/get_username/`,
  generateForgotPasswordOtp: `${BASE}/otp_password/`,
  changePasswordExp: `${BASE}/change_password/`,
  login: `${BASE}/login/`,
  employeeFeedbackHistory: `${BASE}/employee_feedback_history/`,
  changeProfilePssword: `${BASE}/profile_password_update/`,
  UploadRecordingFile: `${BASE}/UploadRecordingFile/`,
  branches: `${BASE}/essential_details/branch_listing/`,
  GetLeadDetail: `${BASE}/GetLeadDetail/`,
  clickToCall: `${BASE}/ClickToCall/`,
  searchCcaLeads: `${BASE}/search_cca_leads/`,
  fetchReportingManager: `${BASE}/fetch_reportingmanager/`,
  employeeProfileList: `${BASE}/employee_profile_list/`,
  pipTagging: `${BASE}/employee_piptag/`,
  getUserDetails: `${BASE}/get_user_details/`,
  addUser: `${BASE}/registration/`,
  userList: `${BASE}/lead_management/user_listing/`,
  registration: `${BASE}/registration/`,
  cityListing: `${BASE}/essential_details/city_listing/`,
  roleListing: `${BASE}/role_listing/`,
  branchListing: `${BASE}/essential_details/branch_listing/`,
  cityDropdown: `${BASE}/essential_details/city_dropdown/`,
  departmentsManagement: `${BASE}/essential_details/department_management/`,
  syncDepartmentsAndDesignation: `${BASE}/essential_details/sync_departments_and_designation/`,
  departmentListing: `${BASE}/essential_details/department_listing/`,
  leadLifeCycleManagement: `${BASE}/lead_management/lead_life_cycle_management/`,
  leadInfo: `${BASE}/lead_management/lead_info/`,
  leadLifeCycleList: `${BASE}/lead_management/life_cycle_listing/`,
  designation_listing: `${BASE}/essential_details/designation_listing/`,
  leadSourceListing: `${BASE}/essential_details/lead_source_listing/`,
  leadSourceTypeListing: `${BASE}/essential_details/source_type_listing/`,
  designationManagement: `${BASE}/essential_details/designation_management/`,
  designationListing: `${BASE}/essential_details/lead_designation_dropdown/`,
  leadTagListing: `${BASE}/lead_management/lead_tag_listing/`,
  leadTagDetail: `${BASE}/lead_management/lead_tag_detail/`,
  leadDetail: `${BASE}/lead_management/lead_detail/`,
  leadStatusListing: `${BASE}/lead_management/lead_status_listing/`,
  leadJourneyListing: `${BASE}/lead_management/lead_journey_listing/`,
  nodeManagement: `${BASE}/lead_management/node_management/`,
  leadListing: `${BASE}/lead_management/lead_list/`,
  assignLeadToHrspoc: `${BASE}/lead_management/assign_lead_to_hrspoc/`,
  assignedLead: `${BASE}/lead_management/assigned_leads/`,
  leadGlobalSearch: `${BASE}/lead_management/lead_global_search/`,
  vacancyList: `${BASE}/lead_management/vacancy_listing/`,
  callingReport: `${BASE}/lead_management/calling_report/`,
  callingDetailReport: `${BASE}/lead_management/calling_report_detail/`,
  leadJourneyReport: `${BASE}/lead_management/lead_journey_report/`,
  usageReport: `${BASE}/lead_management/usage_report/`,
};

export default urls;
