/* eslint-disable */

const getProjectText = () => {
  if (window.location.hostname?.toLowerCase().includes("marketing")) {
    return "marketing";
  } else if (window.location.hostname?.toLowerCase().includes("recruitment")) {
    return "recruitment";
  } else {
    return "recruitment";
  }
};

export default getProjectText;
