/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Modal from "../modal/modal";
import { Button, Typography, withStyles } from "@material-ui/core";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const styles = (theme) => ({
  parentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerText: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

const DeleteDialog = ({
  classes,
  showModal,
  hideModalHandler,
  dialogText,
  successMethod,
  disabled,
  errorMethod,
}) => {
  const width = useWindowDimensions();
  return (
    <Modal
      open={showModal}
      small
      style={
        width > 768 ? { padding: "15px" } : { padding: "15px", width: 250 }
      }
    >
      <div className={classes.parentDiv}>
        <Typography className={classes.headerText}>{dialogText}</Typography>
        <div className={classes.buttonDiv}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={successMethod}
            disabled={disabled}
          >
            Yes
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              if (errorMethod) {
                errorMethod();
              } else {
                hideModalHandler();
              }
            }}
            style={{ marginLeft: 20 }}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteDialog.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModalHandler: PropTypes.func.isRequired,
  dialogText: PropTypes.string.isRequired,
  successMethod: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorMethod: PropTypes.func,
};

DeleteDialog.defaultProps = {
  disabled: false,
  errorMethod: null,
};

export default withStyles(styles)(DeleteDialog);
